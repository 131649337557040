import React from "react"
import styled, { keyframes } from "styled-components"
import Plx from "react-plx"
import useWindowSize from "../../utils/hooks/useWindowSize"

interface Props {
  width: number
}

const HeroDevice = () => {
  const { width } = useWindowSize()

  const ipad = [
    {
      start: "self",
      startOffset: 200,
      duration: 500,
      easing: [0.25, 0.1, 0.6, 1.5],
      properties: [
        {
          startValue: -50,
          endValue: 100,
          property: "translateY",
        },
        {
          startValue: 0.8,
          endValue: 1,
          property: "scale",
        },
      ],
    },
  ]
  const iphoneblue = [
    {
      start: "self",
      startOffset: 250,
      duration: 500,
      easing: [0.25, 0.1, 0.6, 1.5],
      properties: [
        {
          startValue: 0,
          endValue: 100,
          property: "translateY",
        },
        {
          startValue: 0.8,
          endValue: 1,
          property: "scale",
        },
      ],
    },
  ]
  const iphonepink = [
    {
      start: "self",
      startOffset: 300,
      duration: 600,
      easing: [0.25, 0.1, 0.6, 1.5],
      properties: [
        {
          startValue: 0,
          endValue: 290,
          property: "translateY",
        },
        {
          startValue: 0.8,
          endValue: 1,
          property: "scale",
        },
      ],
    },
  ]
  const shadow = [
    {
      start: "self",
      startOffset: 200,
      duration: 500,
      easing: [0.25, 0.1, 0.6, 1.5],
      properties: [
        {
          startValue: 50,
          endValue: 150,
          property: "translateY",
        },
        {
          startValue: 0.8,
          endValue: 1,
          property: "scale",
        },
        {
          startValue: 1,
          endValue: 0,
          property: "opacity",
        },
      ],
    },
  ]

  return (
    <Wrapper width={width}>
      <Plx parallaxData={ipad}>
        <Ipad src="/images/mockups/hero-ipad2.webp" alt="ipad" />
      </Plx>
      <Plx parallaxData={iphoneblue}>
        <IphoneBlue src="/images/mockups/hero-iphone-1.webp" alt="iphone" />
      </Plx>
      <Plx parallaxData={iphonepink}>
        <IphonePink src="/images/mockups/hero-iphone-2.webp" alt="iphone" />
      </Plx>
      <Plx parallaxData={shadow}>
        <Shadow src="/images/mockups/shadow.webp" alt="shadow" />
      </Plx>
    </Wrapper>
  )
}

export default HeroDevice

const fadeIn = keyframes`
from { opacity: 0; }
to { opacity: 1; }
`

const Wrapper = styled.div<Props>`
  position: relative;
  width: 872px;
  height: 672px;
  margin: 169px auto 0;
  padding: 0 20px;
  opacity: 0;
  animation: ${fadeIn} 1s 1s forwards;

  @media (max-width: 1040px) {
    transform-origin: center;
    transform: scale(${props => props.width / 872});
  }
`

const Ipad = styled.img`
  position: absolute;
  width: 476px;
  height: 510px;
  top: 99px;
  left: 0px;
  object-fit: contain;
`

const IphoneBlue = styled.img`
  position: absolute;
  width: 500px;
  height: 442px;
  top: 160px;
  left: 286px;
  object-fit: contain;
`

const IphonePink = styled.img`
  position: absolute;
  width: 468px;
  height: 440px;
  top: 0px;
  left: 461px;
  object-fit: contain;
`

const Shadow = styled.img`
  position: absolute;
  width: 927px;
  height: 92px;
  top: 500px;
  left: 40px;
  object-fit: fill;
`

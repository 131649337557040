import React from "react"
import styled from "styled-components"
import LazyLoad from "react-lazyload"
import useWindowSize from "../../utils/hooks/useWindowSize"

import WallpaperAnimation from "./WallpaperAnimation"

interface StyledProps {
  width: number
}

const WallpaperAnimationSection = () => {
  const { width } = useWindowSize()

  return (
    <Wrapper>
      <LazyLoad height={500}>
        <MockupWrapper width={width}>
          <WallpaperAnimation />
        </MockupWrapper>
      </LazyLoad>
    </Wrapper>
  )
}

export default WallpaperAnimationSection

const Wrapper = styled.div`
  position: relative;
  margin: 0px auto;
  width: 100%;
  overflow: hidden;
`

const MockupWrapper = styled.div<StyledProps>`
  @media (max-width: 700px) {
    transform-origin: left;
    transform: scale(${props => props.width / 700});
    height: ${props => (props.width / 700) * 650}px;
  }
`

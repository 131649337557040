export const wallpapers = [
  {
    images: "/images/illustrations/bg1.png",
  },
  {
    images: "/images/illustrations/bg2.png",
  },
  {
    images: "/images/illustrations/bg3.png",
  },
  {
    images: "/images/illustrations/bg4.png",
  },
  {
    images: "/images/illustrations/bg5.png",
  },
  {
    images: "/images/illustrations/bg6.png",
  },
  {
    images: "/images/illustrations/bg7.png",
  },
  {
    images: "/images/illustrations/bg8.png",
  },
  {
    images: "/images/illustrations/bg9.png",
  },
  {
    images: "/images/illustrations/bg10.png",
  },
  {
    images: "/images/illustrations/bg11.png",
  },
]

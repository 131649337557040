import React from "react"
import styled from "styled-components"
import Plx from "react-plx"

import useDownload from "../../utils/hooks/useDownload"
import { mockupsCount } from "../../data/MockupsData"

import * as TextStyles from "../styles/TextStyles"
import CheckoutButton from "../buttons/CheckoutButton"
import GhostButton from "../buttons/GhostButton"
import HeroBackground from "../backgrounds/HeroBackground"
import LogoWoman from "../graphics/LogoWoman"
import HeroDevice from "../graphics/HeroDevice"

const HeroSection = () => {
  const { download } = useDownload()

  const textData = [
    {
      start: "self",
      duration: "40vh",
      easing: "easeInOut",
      properties: [
        {
          startValue: 150,
          endValue: 0,
          property: "translateY",
        },
        {
          startValue: 0,
          endValue: 1,
          property: "opacity",
        },
      ],
    },
  ]

  return (
    <Wrapper>
      <HeroBackground />
      <HeroDevice />
      <TextWrapper>
        <Title>{mockupsCount.total}</Title>
        <Description>Vector device mockups for Sketch, Figma and XD</Description>
      </TextWrapper>
      <ButtonWrapper>
        <ButtonContainer>
          <Plx className="StickyText" parallaxData={textData}>
            <CheckoutButton type="pro" />
          </Plx>
          <Plx className="StickyText" parallaxData={textData}>
            <Caption>
              Mockups are downloadable. Get free upgrades for new devices for one year.
            </Caption>
          </Plx>
          <Plx className="StickyText" parallaxData={textData}>
            <GhostButton icon="/images/icons/downloads-blue.png" onClick={download} />
          </Plx>
        </ButtonContainer>
        <LogoWoman />
      </ButtonWrapper>
    </Wrapper>
  )
}

export default HeroSection

const Wrapper = styled.div`
  display: grid;
  justify-content: center;
  position: relative;
  overflow: hidden;
`

const TextWrapper = styled.div`
  position: relative;
  text-align: center;
  padding: 160px 20px 0;
`

const Title = styled(TextStyles.H1)``

const Description = styled(TextStyles.H3)`
  max-width: 500px;
  width: 100%;
  margin: 0 auto;

  @media (max-width: 520px) {
    width: 300px;
  }
`

const ButtonWrapper = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 26% 69%;
  gap: 50px;
  max-width: 1160px;
  width: 100%;
  margin: 0 auto;
  padding: 280px 20px 0;
  text-align: left;

  @media (max-width: 900px) {
    grid-template-columns: 1fr;
    gap: 100px;
  }
`

const ButtonContainer = styled.div`
  display: grid;
  gap: 30px;
  max-width: 304px;
  width: 100%;
  height: fit-content;

  @media (max-width: 900px) {
    margin: 0 auto;
  }

  @media (max-width: 520px) {
    max-width: 280px;
  }

  .ghost {
    max-width: 186px;
  }
`

const Caption = styled(TextStyles.MediumText)`
  max-width: 310px;
  color: #6c6a8e;
`

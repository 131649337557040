import React, { useState } from "react"
import styled from "styled-components"

import { mockupsCount } from "../../data/MockupsData"
import { Device } from "../../types/contentfulTypes"
import { DevicesData } from "../../utils/hooks/useContentful"
import LoaderAnimation from "../animations/LoaderAnimation"

import * as TextStyles from "../styles/TextStyles"
import CollectionCard from "../cards/CollectionCard"
import NavigationInput from "../inputs/NavigationInput"
import PillButton from "../buttons/PillButton"
import { navigate } from "gatsby-link"

interface Props {
  devicesData: DevicesData | undefined
}

const MockupsSelectionSection = ({ devicesData }: Props) => {
  const [os, setOS] = useState("iOS")
  const desktopCount = mockupsCount["iPadOS"] + mockupsCount["macOS"]

  return (
    <Wrapper>
      <Form>
        <NavigationInput
          number={mockupsCount["iOS"]}
          name="os"
          value="iOS"
          id="iOS"
          defaultChecked={true}
          onClick={() => {
            setOS("iOS")
          }}
          htmlFor="iOS"
          label="iOS"
          isSelected={os === "iOS"}
        />
        <NavigationInput
          number={mockupsCount["Android"]}
          name="os"
          value="Android"
          id="Android"
          onClick={() => {
            setOS("Android")
          }}
          htmlFor="Android"
          label="Android"
          isSelected={os === "Android"}
        />
        <NavigationInput
          number={desktopCount}
          name="os"
          value="Desktop"
          id="Desktop"
          onClick={() => {
            setOS("Desktop")
          }}
          htmlFor="Desktop"
          label="Desktop"
          isSelected={os === "Desktop"}
        />
      </Form>
      <CardContainer>
        {devicesData ? (
          devicesData[os] ? (
            <CardGridContainer>
              {devicesData[os].map((device: Device, index: number) => (
                <CollectionCard
                  title={device.model}
                  image={
                    `${device.image?.file?.url}?fm=webp` ??
                    "/images/illustrations/collection-iOS.webp"
                  }
                  link={`/mockups/${device.slug}`}
                  key={index}
                  buttonText="View Device"
                />
              ))}
            </CardGridContainer>
          ) : (
            <ContentWrapper>
              <Caption>Sorry, no devices for {os} found.</Caption>
            </ContentWrapper>
          )
        ) : (
          <ContentWrapper>
            <LoaderAnimation />
          </ContentWrapper>
        )}
      </CardContainer>
      {devicesData && (
        <PillButton
          title="View more"
          onClick={() => navigate(`/collections/${os.toLowerCase()}`)}
        />
      )}{" "}
    </Wrapper>
  )
}

export default MockupsSelectionSection

const Wrapper = styled.div`
  position: relative;
  margin: 200px 0;
  padding: 0 20px;

  button {
    margin-top: 40px;
  }
`

const Form = styled.form`
  display: flex;
  width: fit-content;
  justify-content: center;
  margin: 0 auto;

  @media (max-width: 872px) {
    width: 100%;
    transform: scale(0.7);
  }

  @media (max-width: 560px) {
    .number {
      display: none;
    }
  }
`

const CardContainer = styled.div`
  max-width: 932px;
  width: 100%;
  padding-top: 54px;
  margin: 0 auto;
`

const CardGridContainer = styled.div`
  display: grid;
  width: 100%;
  height: fit-content;
  grid-template-columns: repeat(auto-fit, minmax(335px, 1fr));
  gap: 30px;
  justify-content: center;
  justify-items: center;

  .card-wrapper {
    height: 669px;
  }

  @media (max-width: 768px) {
    grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));

    .card-wrapper {
      height: 462px;
      padding: 30px;
    }

    img {
      max-height: 343px;
    }
  }
`

const ContentWrapper = styled.div`
  text-align: center;
  margin: 0 auto;
`

const Caption = styled(TextStyles.Caption)``

import React from "react"
import styled from "styled-components"
import Masonry from "react-masonry-component"

import TextSection from "./TextSection"
import { wallpapers } from "../../data/WallpaperData"
import useDownload from "../../utils/hooks/useDownload"
import WallpaperCard from "../cards/WallpaperCard"
import WallpaperAnimationSection from "../animations/WallpaperAnimationSection"

const WallpaperSection = () => {
  const { download } = useDownload("freeWallpaper")

  const masonryOptions = {
    gutter: 15,
    fitWidth: true,
  }

  return (
    <Wrapper>
      <Wave />
      <Container>
        <WallpaperAnimationSection />
        <TextSection
          title="Wallpapers in 5k resolutions"
          description="Get access to over 120 unique wallpapers at full resolution that can be used commercially for your app projects."
          buttonTitle="Download Wallpapers"
          buttonIcon="/images/icons/downloads-pink.svg"
          onClick={download}
        />
      </Container>
      <Masonry className="masonry" options={masonryOptions}>
        {wallpapers.map((wallpaper, index) => (
          <WallpaperCard key={index} src={wallpaper.images} />
        ))}
      </Masonry>
    </Wrapper>
  )
}

export default WallpaperSection

const Wrapper = styled.div`
  position: relative;
  margin: 200px 0px;

  .masonry {
    margin: 200px auto;
  }

  .text-wrapper {
    h3,
    p {
      color: #ffffff;
    }
    .purchase {
      p {
        color: rgba(0, 0, 0, 0.7);
      }
    }
  }

  @media (max-width: 1050px) {
    .text-wrapper {
      h3,
      p {
        color: #000000;
      }
    }

    @media (prefers-color-scheme: dark) {
      .text-wrapper {
        h3,
        p {
          color: #ffffff;
        }
      }
    }
  }
`

const Container = styled.div`
  position: relative;
  display: grid;
  max-width: 1234px;
  width: 100%;
  grid-template-columns: auto 372px;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  padding: 0 20px;

  @media (max-width: 1050px) {
    grid-template-columns: 1fr;
    gap: 150px;
    justify-content: center;
    justify-items: center;
  }
`

const Wave = styled.div`
  position: absolute;
  width: 100%;
  height: 830px;
  top: -220px;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url("/images/backgrounds/wallpaper-wave.png");
`

import React, { useState, useEffect } from "react"
import styled from "styled-components"

import useDownload from "../../utils/hooks/useDownload"
import useContentful, { DevicesData } from "../../utils/hooks/useContentful"

import MockupsSelectionSection from "./MockupsSelectionSection"
import TextSection from "./TextSection"
import MockupAnimationSection from "../animations/MockupAnimationSection"

const MockupsPreviewSection = () => {
  const { getFeaturedDevices } = useContentful()
  const { download } = useDownload()
  const [devicesData, setDevicesData] = useState<DevicesData | undefined>(undefined)

  useEffect(() => {
    getFeaturedDevices().then(response => response && setDevicesData(response))

    return () => setDevicesData(undefined)
  }, [])

  return (
    <Wrapper>
      <Container>
        <TextSection
          title="iOS, Android and Desktop mockups"
          description="Want the latest devices? We got you covered with over 1000 mockups for all the latest
            devices. Organized in a huuuuge library."
          buttonTitle="Download Sample"
          buttonIcon="/images/icons/downloads-pink.svg"
          onClick={download}
        />
        <MockupAnimationSection />
      </Container>
      <MockupsSelectionSection devicesData={devicesData} />
    </Wrapper>
  )
}

export default MockupsPreviewSection

const Wrapper = styled.div`
  margin: 200px 0px;
  overflow: hidden;
`

const Container = styled.div`
  display: grid;
  max-width: 1234px;
  width: 100%;
  margin: 0 auto;
  grid-template-columns: 372px auto;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 1050px) {
    grid-template-columns: auto;
    gap: 100px;
    justify-content: center;
    justify-items: center;
  }

  @media (max-width: 412px) {
    .text-wrapper {
      max-width: 280px;
    }
  }
`

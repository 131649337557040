import React from "react"
import styled, { keyframes } from "styled-components"
import Stars from "../graphics/Stars"
import Plx from "react-plx"

const HeroBackground = () => {
  const wave1 = [
    {
      start: "self",
      startOffset: 300,
      duration: 500,
      easing: [0.25, 0.1, 0.6, 1.5],
      properties: [
        {
          startValue: 0,
          endValue: 400,
          property: "translateY",
        },
      ],
    },
  ]
  const wave2 = [
    {
      start: "self",
      startOffset: 300,
      duration: 500,
      easing: [0.25, 0.1, 0.6, 1.5],
      properties: [
        {
          startValue: 0,
          endValue: 300,
          property: "translateY",
        },
      ],
    },
  ]
  const wave3 = [
    {
      start: "self",
      startOffset: 300,
      duration: 500,
      easing: [0.25, 0.1, 0.6, 1.5],
      properties: [
        {
          startValue: 0,
          endValue: 300,
          property: "translateY",
        },
      ],
    },
  ]
  const wave4 = [
    {
      start: "self",
      startOffset: 300,
      duration: 500,
      easing: [0.25, 0.1, 0.6, 1.5],
      properties: [
        {
          startValue: 0,
          endValue: 0,
          property: "translateY",
        },
      ],
    },
  ]
  const wave5 = [
    {
      start: "self",
      duration: 500,
      easing: [0.25, 0.1, 0.6, 1.5],
      properties: [
        {
          startValue: 0,
          endValue: 0,
          property: "translateY",
        },
      ],
    },
  ]

  const trees1 = [
    {
      start: "self",
      startOffset: 30,
      duration: 500,
      easing: [0.25, 0.1, 0.6, 1.5],
      properties: [
        {
          startValue: 0,
          endValue: -200,
          property: "translateY",
        },
      ],
    },
  ]
  const trees2 = [
    {
      start: "self",
      startOffset: 0,
      duration: 500,
      easing: [0.25, 0.1, 0.6, 1.5],
      properties: [
        {
          startValue: 0,
          endValue: -200,
          property: "translateY",
        },
      ],
    },
  ]

  return (
    <Wrapper>
      <Plx parallaxData={wave1}>
        <Wave1 />
      </Plx>
      <Plx parallaxData={wave2}>
        <Wave2 />
      </Plx>
      <Plx parallaxData={wave1}>
        <BlurLayer />
      </Plx>
      <Plx parallaxData={wave3}>
        <Wave3 />
      </Plx>
      <Plx parallaxData={wave4}>
        <Wave4 />
      </Plx>
      <Plx parallaxData={trees1}>
        <Trees2 />
      </Plx>
      <Plx parallaxData={wave5}>
        <Wave5 />
      </Plx>
      <Plx parallaxData={trees2}>
        <Trees />
      </Plx>
      <Wave6 />
      <Stars />
    </Wrapper>
  )
}

export default HeroBackground

const fadeIn = keyframes`
from { opacity: 0; }
to { opacity: 1; }
`

const Wrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 1300px;
  background: linear-gradient(180deg, #a14d5d 0%, #fe985e 23.63%);
  text-align: center;
  overflow: hidden;
  animation: ${fadeIn} 4s forwards;

  @media (prefers-color-scheme: dark) {
    background: linear-gradient(180deg, #322d6d 6.33%, #663182 39.13%);
  }
`

const Wave = styled.div`
  position: absolute;
  width: 100%;
  background-position: top center;
  background-repeat: no-repeat;
  background-size: 100%;

  @media (max-width: 1440px) {
    background-size: 1440px;
  }
`

const Wave1 = styled(Wave)`
  top: 190px;
  height: 446px;
  background-image: url("/images/backgrounds/mountain1.svg");

  @media (prefers-color-scheme: dark) {
    background-image: url("/images/backgrounds/mountain1-d.svg");
  }
`

const Wave2 = styled(Wave)`
  top: 160px;
  height: 464px;
  background-image: url("/images/backgrounds/mountain2.svg");

  @media (prefers-color-scheme: dark) {
    background-image: url("/images/backgrounds/mountain2-d.svg");
  }
`

const Wave3 = styled(Wave)`
  top: 347px;
  height: 408px;
  background-image: url("/images/backgrounds/mountain3.svg");
`

const Wave4 = styled(Wave)`
  top: 466px;
  height: 457px;
  background-image: url("/images/backgrounds/mountain4.svg");
`

const Wave5 = styled(Wave)`
  top: 450px;
  height: 867px;
  background-image: url("/images/backgrounds/mountain5.svg");
`

const Wave6 = styled(Wave)`
  top: 700px;
  height: 630px;
  background-image: url("/images/backgrounds/hero-wave.svg");

  @media (prefers-color-scheme: dark) {
    background-image: url("/images/backgrounds/hero-wave-d.svg");
  }
`

const BlurLayer = styled(Wave)`
  top: 100px;
  height: 1503px;
  background-image: url("/images/backgrounds/blur-layer.webp");
`

const Trees = styled.div`
  position: absolute;
  width: 100%;
  height: 412px;
  background-repeat: no-repeat;
  background-image: url("/images/backgrounds/trees.webp");
  top: 350px;

  @media (max-width: 1200px) {
    left: -100px;
  }

  @media (max-width: 900px) {
    left: -200px;
  }

  @media (max-width: 700px) {
    display: none;
  }
`

const Trees2 = styled.div`
  position: absolute;
  width: 470px;
  height: 621px;
  background-repeat: no-repeat;
  background-image: url("/images/backgrounds/trees2.webp");
  top: 85px;
  right: 0px;

  @media (max-width: 1200px) {
    right: -100px;
  }

  @media (max-width: 900px) {
    right: -200px;
  }

  @media (max-width: 700px) {
    display: none;
  }
`

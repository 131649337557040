import React from "react"
import styled from "styled-components"

const Stars = () => {
  return (
    <Wrapper>
      {/* <NightBlur /> */}
      <StarsContainer />
    </Wrapper>
  )
}

export default Stars

export const Wrapper = styled.div`
  position: absolute;
  width: 100%;
  top: 10px;
  display: none;

  @media (prefers-color-scheme: dark) {
    display: block;
  }
`

const StarsContainer = styled.div`
  height: 180px;
  background-position: top center;
  background-repeat: repeat;
  background-image: url("/images/backgrounds/stars.png");
`

const NightBlur = styled.div`
  position: absolute;
  width: 100%;
  height: 502px;
  background-position: top center;
  background-repeat: no-repeat;
  background-size: 100%;
  top: -50px;
  background-image: url("/images/backgrounds/starsBlur.webp");
  /*filter: blur(200px);*/
`

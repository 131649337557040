import React, { useRef } from "react"
import styled from "styled-components"

import { useMouseCenterPosition } from "../../utils/hooks/useMouseCenterPosition"

const LogoWoman = () => {
  const ref = useRef(null)
  const [centerX, centerY, bind] = useMouseCenterPosition(ref)

  return (
    <Wrapper {...bind} ref={ref}>
      {/* <Blur /> */}
      <Illustration
        src="/images/backgrounds/woman.webp"
        alt="woman"
        style={{
          transform: `translateX(${centerX / 20}px) translateY(${centerY / 20}px)`,
        }}
      />
      <XDLogo
        src="/images/logos/xd-glass-logo.webp"
        alt="logo"
        style={{
          transform: `translateX(${centerX / 16}px) translateY(${centerY / 16}px)`,
        }}
      />
      <FigmaLogo
        src="/images/logos/figma-glass-logo.webp"
        alt="logo"
        style={{
          transform: `translateX(${centerX / 14}px) translateY(${centerY / 14}px)`,
        }}
      />
      <SketchLogo
        src="/images/logos/sketch-glass-logo.webp"
        alt="logo"
        style={{
          transform: `translateX(${centerX / 12}px) translateY(${centerY / 12}px)`,
        }}
      />
    </Wrapper>
  )
}

export default LogoWoman

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  max-width: 800px;
  height: 670px;

  * {
    transition: 3s cubic-bezier(0.075, 0.82, 0.165, 1);
    pointer-events: none;
  }
`

const Illustration = styled.img`
  position: absolute;
  max-width: 800px;
  top: -140px;
  margin: 0;

  @media (max-width: 900px) {
    max-width: 600px;
    top: 0px;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    text-align: center;
  }
`

// const Blur = styled.div`
//   display: none;
//   position: absolute;
//   width: 493px;
//   height: 493px;
//   margin: 0 auto;
//   top: -190px;
//   left: 100px;
//   background: linear-gradient(
//     137.73deg,
//     rgba(110, 48, 147, 0.7) 45.61%,
//     rgba(31, 17, 68, 0.7) 85.34%
//   );
//   filter: blur(100px);

//   @media (max-width: 900px) {
//     width: 350px;
//     height: 350px;
//     top: 0px;
//     margin-left: auto;
//     margin-right: auto;
//     left: 0;
//     right: 0;
//     text-align: center;
//   }

//   @media (prefers-color-scheme: dark) {
//     display: block;
//   }
// `

const Logo = styled.img`
  position: absolute;
  max-width: 90px;
  height: auto;
  margin: 0;
`
const XDLogo = styled(Logo)`
  top: -180px;

  @media (max-width: 900px) {
    top: -30px;
  }
`

const FigmaLogo = styled(Logo)`
  top: -220px;
  left: 320px;

  @media (max-width: 900px) {
    top: -70px;
  }
`

const SketchLogo = styled(Logo)`
  top: -200px;
  left: 630px;

  @media (max-width: 900px) {
    top: -50px;
  }
`

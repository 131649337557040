import React from "react"
import styled from "styled-components"

import { Composition } from "../../types/contentfulTypes"
import { useShowModal } from "../../states/context/ModalContext"

interface Props {
  composition: Composition
  compositionList: any
  index: number
  isMoving: boolean
}

const CompositionCard = (props: Props) => {
  const { composition, compositionList, index, isMoving } = props

  const showCompositionModal = useShowModal("composition", {
    compositionList,
    index,
  })

  const handleShowModal = () => {
    if (!isMoving) {
      showCompositionModal()
    }
  }

  return (
    <Wrapper onClick={handleShowModal}>
      <Image src={`${composition?.image?.file?.url}?fm=webp`} alt="composition" />
    </Wrapper>
  )
}

export default CompositionCard

const Wrapper = styled.div`
  max-width: 400px;
  width: 100%;
  padding: 10px;

  &,
  * {
    transition: 0.8s cubic-bezier(0.075, 0.82, 0.165, 1);
  }

  :hover {
    transform: scale(1.05);
  }
`

const Image = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 15px;
  box-shadow: 0px 10px 40px rgba(109, 38, 38, 0.1);
`

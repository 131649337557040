import React, { useState } from "react"
import styled from "styled-components"
import Carousel from "react-multi-carousel"
import "react-multi-carousel/lib/styles.css"
import LazyLoad from "react-lazyload"

import { CompositionMaterial } from "../../types/contentfulTypes"
import { CompositionData } from "./CompositionSection"

import CompositionCard from "../cards/CompositionCard"
import PurpleButton from "../buttons/PurpleButton"
import NavigationInput from "../inputs/NavigationInput"
import * as TextStyles from "../styles/TextStyles"

interface Props {
  compositionsData: CompositionData | undefined
}

const CompositionCarouselSection = ({ compositionsData }: Props) => {
  const [isMoving, setIsMoving] = useState(false)
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1920 },
      items: 5,
      partialVisibilityGutter: 60,
    },
    tablet: {
      breakpoint: { max: 1920, min: 768 },
      items: 3,
      partialVisibilityGutter: 40,
    },
    mobile: {
      breakpoint: { max: 768, min: 0 },
      items: 2,
      partialVisibilityGutter: 20,
    },
  }

  const [material, setMaterial] = useState<CompositionMaterial>("Real")
  let filteredComps = compositionsData && compositionsData[material]

  return (
    <Wrapper>
      <Form>
        <NavigationInput
          number={(compositionsData && compositionsData["Real"]?.length) ?? 0}
          name="material"
          value="real"
          id="real"
          defaultChecked={true}
          onClick={() => {
            setMaterial("Real")
          }}
          htmlFor="real"
          label="Real"
          isSelected={material === "Real"}
        />
        <NavigationInput
          number={(compositionsData && compositionsData["Clay"]?.length) ?? 0}
          name="material"
          value="clay"
          id="clay"
          onClick={() => setMaterial("Clay")}
          htmlFor="clay"
          label="Clay"
          isSelected={material === "Clay"}
        />
        <NavigationInput
          number={(compositionsData && compositionsData["Glass"]?.length) ?? 0}
          name="material"
          value="glass"
          id="glass"
          onClick={() => setMaterial("Glass")}
          htmlFor="glass"
          label="Glass"
          isSelected={material === "Glass"}
        />
        <NavigationInput
          number={(compositionsData && compositionsData["Card"]?.length) ?? 0}
          name="material"
          value="cards"
          id="cards"
          onClick={() => setMaterial("Card")}
          htmlFor="cards"
          label="Cards"
          isSelected={material === "Card"}
        />
      </Form>
      {filteredComps ? (
        <Carousel
          swipeable={true}
          draggable={true}
          showDots={false}
          responsive={responsive}
          partialVisible={true}
          infinite={true}
          customTransition="0.8s cubic-bezier(0.075, 0.82, 0.165, 1)"
          transitionDuration={500}
          removeArrowOnDeviceType={["mobile"]}
          customLeftArrow={<PurpleButton icon="/images/icons/chevron-left.svg" isLeft={true} />}
          customRightArrow={<PurpleButton icon="/images/icons/chevron-right.svg" isLeft={false} />}
          beforeChange={() => setIsMoving(true)}
          afterChange={() => setIsMoving(false)}
        >
          {filteredComps.map((_comp, indexComp) => {
            return (
              indexComp % 2 === 0 && (
                <CompositionCardsWrapper key={indexComp}>
                  {filteredComps &&
                    [filteredComps[indexComp], filteredComps[indexComp + 1]].map(
                      (composition, index) =>
                        composition && (
                          <CompositionCard
                            key={index}
                            composition={composition}
                            compositionList={filteredComps}
                            index={indexComp + index}
                            isMoving={isMoving}
                          />
                        )
                    )}
                </CompositionCardsWrapper>
              )
            )
          })}
        </Carousel>
      ) : (
        <Caption>Sorry, no compositions found for {material}.</Caption>
      )}
    </Wrapper>
  )
}

export default CompositionCarouselSection

const Wrapper = styled.div``

const Form = styled.form`
  display: flex;
  width: fit-content;
  margin: 0 auto;
  padding-bottom: 37px;

  @media (max-width: 872px) {
    width: 100%;
    transform: scale(0.7);
  }

  @media (max-width: 560px) {
    .number {
      display: none;
    }
  }
`

const CompositionCardsWrapper = styled.div`
  display: grid;
  padding: 20px 0px;
`

const Caption = styled(TextStyles.Caption)`
  padding-top: 20px;
`

import React from "react"
import styled, { keyframes } from "styled-components"
import { useInView } from "react-intersection-observer"

interface StyledProps {
  inView: boolean
}

const WallpaperAnimation = () => {
  const [ref, inView] = useInView()

  return (
    <Wrapper ref={ref} inView={inView}>
      <svg className="desktopline1" width="189" height="162" viewBox="0 0 189 162" fill="none">
        <path
          id="desktopline1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.999878 154.286V24.155C0.999878 20.7439 3.55497 17.8736 6.94317 17.4785L180.868 1.2668C184.861 0.801164 188.368 3.92252 188.368 7.94329V154.286C188.368 157.999 185.358 161.008 181.646 161.008H7.7216C4.00929 161.008 0.999878 157.999 0.999878 154.286Z"
          stroke="#8846F4"
        />
      </svg>
      <svg className="desktopline2" width="203" height="310" viewBox="0 0 203 310" fill="none">
        <path
          id="desktopline2"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.17114 304.123V5.75337C1.17114 2.80532 3.7087 0.497198 6.64356 0.775739L197.308 18.8712C199.875 19.1149 201.836 21.2705 201.836 23.8489V270.58C201.836 273.007 200.093 275.084 197.702 275.504L7.03747 309.047C3.97655 309.586 1.17114 307.231 1.17114 304.123Z"
          stroke="#8846F4"
        />
      </svg>
      <svg className="desktopline3" width="105" height="90" viewBox="0 0 105 90" fill="none">
        <path
          id="desktopline3"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.533203 85.3122V14.0442C0.533203 12.176 1.93253 10.604 3.78813 10.3877L100.04 1.50913C102.227 1.25412 104.148 2.96357 104.148 5.1656V85.3122C104.148 87.3453 102.5 88.9935 100.466 88.9935H4.21444C2.18135 88.9935 0.533203 87.3453 0.533203 85.3122Z"
          stroke="#8846F4"
        />
      </svg>
      <svg className="desktopline4" width="209" height="183" viewBox="0 0 209 183" fill="none">
        <path
          id="desktopline4"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.20923 174.56V30.6768C1.20923 26.9052 4.03432 23.7316 7.78058 23.2948L200.084 0.873722C204.5 0.358881 208.377 3.81008 208.377 8.25575V174.56C208.377 178.664 205.05 181.992 200.945 181.992H8.64126C4.53666 181.992 1.20923 178.664 1.20923 174.56Z"
          stroke="#8846F4"
        />
      </svg>
      <img
        className="shapes"
        src="../../images/backgrounds/wallpaper-shape.png"
        alt="illustration shape"
      ></img>
      <img
        className="desktop1"
        src="../../images/illustrations/wallpaper-desktop1.png"
        alt="illustration desktop"
      ></img>
      <img
        className="desktop2"
        src="../../images/illustrations/wallpaper-desktop2.png"
        alt="illustration desktop"
      ></img>
      <img
        className="desktop4"
        src="../../images/illustrations/wallpaper-desktop4.png"
        alt="illustration desktop"
      ></img>
      <img
        className="desktop3"
        src="../../images/illustrations/wallpaper-desktop3.png"
        alt="illustration desktop"
      ></img>
      <img className="woman1" src="../../images/illustrations/woman1.svg" alt="illustration "></img>
      <img className="woman2" src="../../images/illustrations/woman2.svg" alt="illustration"></img>
    </Wrapper>
  )
}

export default WallpaperAnimation

const dash = keyframes`
    0% {
      stroke-dashoffset: 2022;
      opacity: 1;
    }
    80% {
      opacity: 1;
    }
    100% {
      stroke-dashoffset: 0;
      opacity: 0;
    }
`

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
 100% {
    opacity: 1;
  }
`

const Wrapper = styled.div<StyledProps>`
  position: relative;
  width: 666px;
  height: 600px;
  margin: 0 auto;

  #desktopline1,
  #desktopline2,
  #desktopline3,
  #desktopline4 {
    stroke-dasharray: 2000;
    stroke-dashoffset: 2000;
    animation: ${dash} 1s linear forwards;
    animation-play-state: ${props => (props.inView ? "running" : "paused")};
  }
  #desktopline2 {
    animation: ${dash} 1s 1s linear forwards;
    animation-play-state: ${props => (props.inView ? "running" : "paused")};
  }
  #desktopline3 {
    animation: ${dash} 1s 2.5s linear forwards;
    animation-play-state: ${props => (props.inView ? "running" : "paused")};
  }
  #desktopline4 {
    animation: ${dash} 1s 2s linear forwards;
    animation-play-state: ${props => (props.inView ? "running" : "paused")};
  }
  .desktopline1 {
    position: absolute;
    left: 98px;
    top: 59px;
  }
  .desktopline2 {
    position: absolute;
    left: 341px;
    top: 56.7px;
  }
  .desktopline3 {
    position: absolute;
    left: 142px;
    top: 214.46px;
  }
  .desktopline4 {
    position: absolute;
    left: 185.21px;
    top: 226.8px;
  }
  .desktop1 {
    position: absolute;
    width: 227px;
    height: 200px;
    left: 78px;
    top: 50.2px;
    opacity: 0;
    animation: ${fadeIn} 0.5s 0.8s linear forwards;
    animation-play-state: ${props => (props.inView ? "running" : "paused")};
  }
  .desktop2 {
    position: absolute;
    width: 261px;
    height: 369x;
    left: 313.17px;
    top: 56.7px;
    opacity: 0;
    animation: ${fadeIn} 0.5s 1.5s linear forwards;
    animation-play-state: ${props => (props.inView ? "running" : "paused")};
  }
  .desktop3 {
    position: absolute;
    width: 164px;
    height: 148px;
    left: 112.54px;
    top: 214.46px;
    opacity: 0;
    animation: ${fadeIn} 0.5s 3s linear forwards;
    animation-play-state: ${props => (props.inView ? "running" : "paused")};
  }
  .desktop4 {
    position: absolute;
    width: 212px;
    height: 181.5px;
    left: 185.21px;
    top: 226.8px;
    opacity: 0;
    animation: ${fadeIn} 1s 2.5s linear forwards;
    animation-play-state: ${props => (props.inView ? "running" : "paused")};
  }
  .woman1 {
    position: absolute;
    width: 123.86px;
    height: 269.63px;
    left: 30px;
    top: 200.78px;
    opacity: 0;
    animation: ${fadeIn} 0.5s 3.5s linear forwards;
    animation-play-state: ${props => (props.inView ? "running" : "paused")};
  }
  .woman2 {
    position: absolute;
    width: 190.43px;
    height: 282.02px;
    left: 490px;
    top: 200.14px;
    opacity: 0;
    animation: ${fadeIn} 0.5s 3.5s linear forwards;
    animation-play-state: ${props => (props.inView ? "running" : "paused")};
  }
  .shapes {
    position: absolute;
    width: 387px;
    height: 360px;
    left: 140px;
    top: 25px;
    opacity: 0;
    animation: ${fadeIn} 0.5s 3.5s linear forwards;
    animation-play-state: ${props => (props.inView ? "running" : "paused")};
  }

  .text-wrapper {
    h3,
    p {
      color: #ffffff;

      @media (max-width: 900px) {
        color: #000000;

        @media (prefers-color-scheme: dark) {
          color: #ffffff;
        }
      }
    }
  }

  .purchase {
    p {
      color: rgba(0, 0, 0, 0.7);
    }
  }
`

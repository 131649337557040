import React, { useEffect, useState } from "react"
import styled from "styled-components"
import { StaticImage } from "gatsby-plugin-image"
import { navigate } from "gatsby"

import useContentful from "../../utils/hooks/useContentful"
import { Composition } from "../../types/contentfulTypes"

import CompositionCarouselSection from "../sections/CompositionCarouselSection"
import TextSection from "./TextSection"

export interface CompositionData {
  [compositions: string]: Composition[] | undefined
}
const CompositionSection = () => {
  const { getCompositions } = useContentful()
  const [compositions, setCompositions] = useState<CompositionData | undefined>(undefined)

  useEffect(() => {
    getCompositions().then(response => response && setCompositions(response))

    return () => setCompositions(undefined)
  }, [])

  return (
    <Wrapper>
      <Circle />
      <StaticImage
        className="devices"
        src="../../images/illustrations/illustration3.webp"
        alt="device composition"
      />
      <TextSection
        title="Compositions made from Angle"
        description="There are endless ways to compose your mockups by editing, changing elements and
          backgrounds. Here are some examples. Learn more from our tutorial."
        buttonTitle="View All Collections"
        buttonIcon="/images/icons/device-pink.svg"
        onClick={() => navigate("/mockups")}
      />
      <CompositionCarouselSection compositionsData={compositions} />
    </Wrapper>
  )
}

export default CompositionSection

const Wrapper = styled.div`
  position: relative;
  text-align: center;
  margin: 200px 0px;
  overflow-x: clip;

  .text-wrapper {
    text-align: center;
    justify-items: center;
    margin: 0 auto;
    padding-bottom: 117px;
  }

  .devices {
    max-width: 373px;
    margin: 0 auto;
  }
`

const Circle = styled.div`
  position: absolute;
  max-width: 327px;
  max-height: 327px;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  margin-left: auto;
  margin-right: auto;
  top: -97px;
  left: 72px;
  right: 0;
  background: radial-gradient(42.01% 59.09% at 42.01% 38.18%, #8338a8 0%, #1f1144 100%);
`

import React from "react"
import styled from "styled-components"

interface Props {
  number: number
  name: string
  value: string
  id: string
  defaultChecked?: boolean
  onClick: () => void
  htmlFor: string
  label: string
  isSelected: boolean
}

interface StyledProps {
  isSelected: boolean
}

const NavigationInput = (props: Props) => {
  const { number, name, value, id, defaultChecked, onClick, htmlFor, label, isSelected } = props

  return (
    <Wrapper>
      <Container>
        <Number onClick={onClick} isSelected={isSelected} className="number">
          {number}
        </Number>
        <Input
          type="radio"
          name={name}
          value={value}
          id={id}
          defaultChecked={defaultChecked}
          onClick={onClick}
        />
        <Label htmlFor={htmlFor} isSelected={isSelected}>
          {label}
        </Label>
      </Container>
      <Separator isSelected={isSelected} />
    </Wrapper>
  )
}

export default NavigationInput

const Wrapper = styled.div`
  display: grid;
  width: 208px;
  gap: 40px;
`

const Container = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  justify-content: center;
  align-items: center;
  gap: 10px;
`

const Separator = styled.div<StyledProps>`
  width: 100%;
  height: 1px;
  background: rgba(31, 17, 68, 0.3);
  border-radius: 10px;
  border: ${props => (props.isSelected ? "2px solid #8338a8" : "1px solid rgba(31, 17, 68, 0.3)")};

  @media (prefers-color-scheme: dark) {
    border: ${props =>
      props.isSelected ? "2px solid #00CFFD" : "1px solid rgba(255, 255, 255, 0.3)"};
  }
`

const Input = styled.input`
  display: none;
`

const Number = styled.div<StyledProps>`
  width: fit-content;
  padding: 10px;
  background: ${props =>
    props.isSelected ? "linear-gradient(180deg, #8338a8 180%, #1f1144 180.83%)" : "none"};
  border: ${props =>
    props.isSelected ? "2px solid rgba(255, 255, 255, 0.2)" : "2px solid rgba(131, 56, 168, 0.5)"};
  box-sizing: border-box;
  border-radius: 40px;
  cursor: pointer;

  font-style: normal;
  font-weight: 800;
  font-size: 20px;
  line-height: 130%;
  color: ${props => (props.isSelected ? "rgba(255, 255, 255, 0.7)" : "rgba(131, 56, 168, 0.5)")};

  @media (prefers-color-scheme: dark) {
    background: ${props =>
      props.isSelected ? "linear-gradient(180deg, #00CFFD 180%, #1f1144 180.83%)" : "none"};
    border: ${props =>
      props.isSelected ? "2px solid rgba(255, 255, 255, 0.2)" : "2px solid rgba(0, 207, 253, 0.5)"};
    color: ${props => (props.isSelected ? "rgba(255, 255, 255, 0.7)" : "rgba(0, 207, 253, 0.5)")};
  }
`

const Label = styled.label<StyledProps>`
  color: ${props => (props.isSelected ? "#8338a8" : " rgba(131, 56, 168, 0.5)")};
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 130%;
  cursor: pointer;

  @media (prefers-color-scheme: dark) {
    color: ${props => (props.isSelected ? "#00CFFD" : "rgba(0, 198, 242, 0.5)")};
  }
`

import React from "react"
import styled, { keyframes } from "styled-components"
import { useInView } from "react-intersection-observer"

interface StyledProps {
  inView: boolean
}

const MockupAnimation = () => {
  const [ref, inView] = useInView()

  return (
    <Wrapper ref={ref} inView={inView}>
      <svg className="desktopline1" width="530" height="283" viewBox="0 0 530 283" fill="none">
        <path
          id="desktopline1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.16504 13.5248C1.16504 6.60054 6.77827 0.987305 13.7025 0.987305H516.874C523.798 0.987305 529.412 6.60053 529.412 13.5248V269.29C529.412 276.214 523.798 281.827 516.874 281.827H13.7025C6.77826 281.827 1.16504 276.214 1.16504 269.29V13.5248Z"
          stroke="#8846F4"
        />
      </svg>
      <svg className="desktopline2" width="272" height="207" viewBox="0 0 272 207" fill="none">
        <path
          id="desktopline2"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.506836 13.3456V193.05C0.506836 199.974 6.12007 205.587 13.0443 205.587H258.779C265.704 205.587 271.317 199.974 271.317 193.05V13.3456C271.317 6.42134 265.704 0.808105 258.779 0.808105H13.0443C6.12007 0.808105 0.506836 6.42134 0.506836 13.3456Z"
          stroke="#8846F4"
        />
      </svg>
      <svg className="phoneline" width="119" height="251" viewBox="0 0 119 251" fill="none">
        <path
          id="phoneline"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.55957 236.857V13.973C0.55957 6.81551 5.5542 1.01318 11.7154 1.01318H107.096C113.257 1.01318 118.252 6.81553 118.252 13.9731V236.857C118.252 244.015 113.257 249.817 107.096 249.817H11.7154C5.5542 249.817 0.55957 244.015 0.55957 236.857Z"
          stroke="#8846F4"
        />
      </svg>
      <img
        className="shape"
        src="../../images/backgrounds/mockupsection-bg.png"
        alt="illustration"
      ></img>
      <img
        className="desktop1"
        src="../../images/illustrations/mockup-desktop1.svg"
        alt="illustration"
      ></img>
      <img
        className="desktop2"
        src="../../images/illustrations/mockup-desktop2.svg"
        alt="illustration"
      ></img>
      <img
        className="phone"
        src="../../images/illustrations/mockup-phone.svg"
        alt="illustration"
      ></img>
      <img
        className="woman"
        src="../../images/illustrations/mockupsection-woman.svg"
        alt="illustration"
      ></img>
      <img
        className="man"
        src="../../images/illustrations/mockupsection-man.svg"
        alt="illustration"
      ></img>
    </Wrapper>
  )
}

export default MockupAnimation

const dash = keyframes`
    0% {
      stroke-dashoffset: 2022;
      opacity: 1;
    }
    80% {
      opacity: 1;
    }
    100% {
      stroke-dashoffset: 0;
      opacity: 0;
    }
`
const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
 100% {
    opacity: 1;
  }
`
const fadeIn2 = keyframes`
  0% {
    opacity: 0;
    transform: scale(.9);
  }
 100% {
    opacity: 1;
    transform: scale(1);
  }
`

const Wrapper = styled.div<StyledProps>`
  position: relative;
  width: 964px;
  height: 630px;
  margin: 0 auto;

  #desktopline1,
  #desktopline2,
  #phoneline {
    stroke-dasharray: 2000;
    stroke-dashoffset: 2000;
    animation: ${dash} 1.5s linear forwards;
    animation-play-state: ${props => (props.inView ? "running" : "paused")};
  }
  #desktopline2 {
    animation: ${dash} 1s 0.5s linear forwards;
    animation-play-state: ${props => (props.inView ? "running" : "paused")};
  }
  #phoneline {
    animation: ${dash} 0.8s 0.8s linear forwards;
    animation-play-state: ${props => (props.inView ? "running" : "paused")};
  }

  .man {
    position: absolute;
    left: 518px;
    top: 410px;
    opacity: 0;
    animation: ${fadeIn} 1s 2.5s linear forwards;
    animation-play-state: ${props => (props.inView ? "running" : "paused")};
  }
  .woman {
    position: absolute;
    left: 235px;
    top: 195px;
    opacity: 0;
    animation: ${fadeIn} 1s 2.5s linear forwards;
    animation-play-state: ${props => (props.inView ? "running" : "paused")};
  }
  .plant {
    position: absolute;
    left: 0px;
    top: 50px;
  }
  .shape {
    position: absolute;
    left: 0px;
    top: 45px;
    opacity: 0;
    animation: ${fadeIn2} 0.8s 2s cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards;
    animation-play-state: ${props => (props.inView ? "running" : "paused")};
  }
  .phoneline {
    position: absolute;
    left: 643px;
    top: 281px;
  }
  .phone {
    position: absolute;
    width: 117.69px;
    height: 248.8px;
    left: 643px;
    top: 281px;
    opacity: 0;
    animation: ${fadeIn} 0.5s 1.5s linear forwards;
    animation-play-state: ${props => (props.inView ? "running" : "paused")};
  }
  .desktopline2 {
    position: absolute;
    left: 350px;
    top: 191px;
  }
  .desktop2 {
    position: absolute;
    width: 270.81px;
    height: 204.78px;
    left: 350px;
    top: 191px;
    opacity: 0;
    animation: ${fadeIn} 0.5s 1.2s linear forwards;
    animation-play-state: ${props => (props.inView ? "running" : "paused")};
  }
  .desktopline1 {
    position: absolute;
    left: 150px;
    top: 300px;
  }
  .desktop1 {
    position: absolute;
    width: 528.25px;
    height: 280.84px;
    left: 150px;
    top: 300px;
    opacity: 0;
    animation: ${fadeIn} 0.5s 1s linear forwards;
    animation-play-state: ${props => (props.inView ? "running" : "paused")};
  }
  .box {
    position: relative;
    width: 963.74px;
    height: 588.13px;
    left: 0px;
    top: 0px;
    right: -15%;
    padding: 0 20px;
  }
`

import React from "react"
import styled from "styled-components"

interface Props {
  src: string
}

const WallpaperCard = (props: Props) => {
  const { src } = props

  return <Card src={src} />
}

export default WallpaperCard

const Card = styled.img`
  max-width: 380px;
  width: 100%;
  margin-bottom: 15px;
  border-radius: 15px;
  box-shadow: 0px 15.2155px 30.431px rgba(0, 0, 0, 0.1);

  &,
  * {
    transition: 0.8s cubic-bezier(0.075, 0.82, 0.165, 1);
  }

  :hover {
    transform: scale(1.05);
  }

  @media (max-width: 440px) {
    max-width: 260px;
  }
`

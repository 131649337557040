import React from "react"
import styled from "styled-components"
import { graphql } from "gatsby"
import LazyLoad from "react-lazyload"

import Layout from "../components/layout/Layout"
import { MetadataReadonly } from "../types/contentfulTypes"

import HeroSection from "../components/sections/HeroSection"
import PluginSection from "../components/sections/PluginSection"
import CompositionSection from "../components/sections/CompositionSection"
import MockupsPreviewSection from "../components/sections/MockupsPreviewSection"
import WallpaperSection from "../components/sections/WallpaperSection"
import TestimonialsSection from "../components/sections/TestimonialsSection"
import FaqSection from "../components/sections/FaqSection"
import PricingSection from "../components/sections/PricingSection"

interface Props {
  data: {
    contentfulMetadata: MetadataReadonly
  }
}

const IndexPage = (props: Props) => {
  const { data } = props

  return (
    <Wrapper>
      <Layout {...data}>
        <HeroSection />
        <LazyLoad offset={200}>
          <PluginSection />
        </LazyLoad>
        <LazyLoad offset={200}>
          <CompositionSection />
        </LazyLoad>
        <LazyLoad offset={200}>
          <MockupsPreviewSection />
        </LazyLoad>
        <LazyLoad offset={200}>
          <WallpaperSection />
        </LazyLoad>
        <LazyLoad offset={200}>
          <TestimonialsSection />
        </LazyLoad>
        <LazyLoad offset={200}>
          <FaqSection />
        </LazyLoad>
        <LazyLoad offset={200}>
          <PricingSection />
        </LazyLoad>
      </Layout>
    </Wrapper>
  )
}

export default IndexPage

export const query = graphql`
  query IndexPageQuery {
    contentfulMetadata(slug: { eq: "home" }) {
      ...MetadataFragment
    }
  }
`

const Wrapper = styled.div`
  .navigation-title {
    color: #ffffff;
  }
`

import React from "react"
import styled from "styled-components"
import LazyLoad from "react-lazyload"
import useWindowSize from "../../utils/hooks/useWindowSize"

import MockupAnimation from "./MockupAnimation"

interface StyledProps {
  width: number
}

const MockupAnimationSection = () => {
  const { width } = useWindowSize()

  return (
    <Wrapper>
      <LazyLoad height={630}>
        <MockupWrapper width={width}>
          <MockupAnimation />
        </MockupWrapper>
      </LazyLoad>
    </Wrapper>
  )
}

export default MockupAnimationSection

const Wrapper = styled.div`
  position: relative;
  margin: 0px auto;
  width: 100%;
`

const MockupWrapper = styled.div<StyledProps>`
  @media (max-width: 1010px) {
    transform-origin: top center;
    transform: scale(${props => props.width / 1010});
    height: ${props => (props.width / 1010) * 630}px;
  }
`
